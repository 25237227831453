import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={createTheme({
    palette: {
      mode: 'dark',
    },
  })}>
    <CssBaseline />
    <App />
  </ThemeProvider>
);