import { Box } from "@mui/material";

function PickLayer({ picks, size, offset, ref }: { picks: number[]; size: number, offset: number, ref: any }) {
  return (
    <Box
      ref={ref}
      sx={{
        width: `${size}px`,
        aspectRatio: "1/1",

        borderRadius: "50%",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
        
      {picks.map((pick) => (
        <Box
          sx={{
            position: "absolute",
            width: "15px",
            height: "30px",

            transform: `rotate(${offset * 15 + pick * 15}deg) translateY(${
              size / 2 - 11
            }px)`,

            background: "#fff",
            transition: "transform linear 0.05s"
          }}
        />
      ))}
    </Box>
  );
}

export default PickLayer;
