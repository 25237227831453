import { Box } from "@mui/material";
import useLevelState from "../states/level";

function PickLayerPreview({
  picks,
  offset,
  index,
}: {
  picks: number[];
  offset: number;
  index: number;
}) {
  const { pickSelected, setPickSelected } = useLevelState();

  return (
    <Box
      sx={{
        width: `100px`,
        aspectRatio: "1/1",

        borderRadius: "50%",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => setPickSelected(index)}
    >
      <Box
        sx={{
          position: "absolute",
          width: "80px",
          aspectRatio: "1/1",

          borderRadius: "50%",
          border: "2px dotted #888",
        }}
      />
      {picks.map((pick) => (
        <Box
          sx={{
            position: "absolute",
            width: "6px",
            height: "12px",

            transform: `rotate(${offset * 15 + pick * 15}deg) translateY(${
              100 / 2 - 11
            }px)`,

            background: "#fff",
            transition: "transform linear 0.1s",
          }}
        />
      ))}
      <Box
        sx={{
          position: "absolute",
          width: pickSelected === index ? "40px" : "0px",
          aspectRatio: "1/1",

          borderRadius: "50%",
          background: "#AAA",
          transition: "all linear 0.3s",
        }}
      />
    </Box>
  );
}

export default PickLayerPreview;
