import {
  Backdrop,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import LockLayer from "./components/LockLayer";
import PickLayer from "./components/PickLayer";
import { useEffect, useRef, useState } from "react";
import useLevelState, { PossibleInsets } from "./states/level";
import generateLevel from "./levelGenerator";
import PickLayerPreview from "./components/PickLayerPreview";

function App() {
  const {
    picks,
    pickSelected,
    layers,
    setPickSelected,
    removePick,
    removeInsets,
    removeCurrLayer,
  } = useLevelState();

  const pickRef = useRef<HTMLDivElement>(null);
  const [difficulty, setDifficulty] = useState<number | null>(null);
  const [showFailScreen, setShowFailScreen] = useState<boolean>(false);

  const [solvedAudio] = useState(new Audio("/sounds/solved.ogg"));
  const [finishedAudio] = useState(new Audio("/sounds/finished.ogg"));
  const [themeAudio] = useState(new Audio("/sounds/theme.mp3"));

  useEffect(() => {
    if (!difficulty) return;

    switch (difficulty) {
      case 1:
        generateLevel({
          layerCountRange: [1, 2],
          insetsPerLayerRange: [2, 4],
          maxPinsPerPick: 2,
        });
        break;
      case 2:
        generateLevel({
          layerCountRange: [3, 4],
          insetsPerLayerRange: [3, 5],
          maxPinsPerPick: 5,
        });
        break;
      case 3:
        generateLevel({
          layerCountRange: [5, 6],
          insetsPerLayerRange: [6, 9],
          maxPinsPerPick: 4,
        });
        themeAudio.play();
        const endEvent = () => {
          const audio = new Audio("/sounds/fail.ogg");
          audio.play();
          setShowFailScreen(true);
        };

        themeAudio.addEventListener("ended", endEvent);

        return () => themeAudio.removeEventListener("ended", endEvent);
        break;
      case 4:
        generateLevel({
          layerCountRange: [10, 10],
          insetsPerLayerRange: [9, 9],
          maxPinsPerPick: 5,
        });
        themeAudio.play();
        const eventEnd = () => {
          const audio = new Audio("/sounds/fail.ogg");
          audio.play();
          setShowFailScreen(true);
        };

        themeAudio.addEventListener("ended", eventEnd);

        return () => themeAudio.removeEventListener("ended", eventEnd);
        break;
    }
  }, [difficulty, themeAudio]);

  useEffect(() => {
    const keyListener = (key: KeyboardEvent) => {
      if (picks.length === 0) return;
      const currPick = picks[pickSelected];
      const audio = new Audio("/sounds/scroll.mp3");
      if (key.key === "a") {
        currPick.rotateCCW();
        audio.play();
      }
      if (key.key === "d") {
        currPick.rotateCW();
        audio.play();
      }
      if (key.key === "w") setPickSelected((pickSelected + 1) % picks.length);
      if (key.key === "s")
        setPickSelected((pickSelected - 1 + picks.length) % picks.length);

      if (key.key === " ") {
        const pins = picks[pickSelected].pins;
        let correct = true;
        let hits: PossibleInsets[] = [];

        for (let i = 0; i < pins.length; i++) {
          let hit = false;
          let pinPos =
            (((pins[i] + picks[pickSelected].offset) * 15) % 360) / 15;

          for (let j = 0; j < layers[0].insets.length; j++) {
            console.log(pinPos, layers[0].insets[j])
            if (pinPos === layers[0].insets[j]) {
              hit = true;
              hits.push(layers[0].insets[j]);
              break;
            }
          }

          if (!hit) {
            correct = false;
            break;
          }
        }

        if (correct) {
          console.log("Correct!");
          removePick(pickSelected);
          setPickSelected((pickSelected - 1 + picks.length) % picks.length);
          const insetsLeft = removeInsets(hits);
          if (insetsLeft === 0) {
            removeCurrLayer();
            if (layers.length === 0) {
              finishedAudio.play();
            } else solvedAudio.play();
            pickRef.current?.style.setProperty(
              "animation",
              "jump 1s ease-in-out 0s infinite alternate"
            );
            setTimeout(() => {
              pickRef.current?.style.setProperty("animation", "");
            });
          }
        } else {
          console.log("Incorrect!");
          const audio = new Audio("/sounds/denied.mp3");
          audio.play();
        }
      }
    };

    const wheelListener = (event: WheelEvent) => {
      if (picks.length === 0) return;
      const audio = new Audio("/sounds/scroll.mp3");
      const currPick = picks[pickSelected];
      if (event.deltaY < 0) currPick.rotateCW();
      if (event.deltaY > 0) currPick.rotateCCW();
      audio.play();
    };

    document.addEventListener("keydown", keyListener);
    document.addEventListener("wheel", wheelListener);

    return () => {
      document.removeEventListener("keydown", keyListener);
      document.removeEventListener("wheel", wheelListener);
    };
  }, [pickSelected, picks]);

  if (difficulty === null)
    return (
      <Backdrop open={true}>
        <Box
          sx={{
            width: "auto",
            height: "auto",

            padding: "30px",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#202020",
          }}
        >
          <Typography
            variant="h1"
            sx={{ color: "white", fontSize: "4rem", fontWeight: "bold" }}
          >
            Locksmith
          </Typography>

          <Select
            sx={{ marginTop: "30px", width: "200px" }}
            value={difficulty}
            onChange={(e) => {
              if (!e.target.value) return;
              setDifficulty(parseInt(e.target.value));
            }}
          >
            <MenuItem value={1}>Easy</MenuItem>
            <MenuItem value={2}>Medium</MenuItem>
            <MenuItem value={3}>Hard</MenuItem>
            <MenuItem value={4}>💀</MenuItem>
          </Select>
        </Box>
      </Backdrop>
    );

  if (showFailScreen)
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100vw",
          height: "100vh",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "red",
            textShadow: "0px 0px 10px red",
            fontSize: "10rem",
            fontWeight: "bold",
            textAlign: "center",
            userSelect: "none",
            transition: "all linear 0.5s",

            "@keyframes success": {
              "0%": {
                color: "#fff",
                textShadow: "0px 0px 30px #fff",
                clipPath: "polygon(0 100%, 100% 0, 20% 0%, 0 100%)",
                transform: "scale(1)",
              },
              "25%": {
                clipPath: "polygon(0% 0%, 100% 100%, 100% 0%, 100% 100%)",
                transform: "scale(7)",
              },
              "50%": {
                color: "red",
                textShadow: "0px 0px 10px red",
                clipPath: "polygon(100% 0%, 0% 100%, 0% 100%, 100% 0%)",
                transform: "scale(3)",
              },
              "100%": {
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                transform: "scale(1)",
              },
            },
            animation: "success 1.25s linear 0s forwards",
          }}
        >
          Fail
        </Typography>

        <Button
          variant="contained"
          onClick={() => {
            window.location.reload();
          }}
        >
          Restart
        </Button>
      </Box>
    );

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",

        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {layers.map((layer, index) => (
        <LockLayer insets={layer.insets} size={700 - index * 100} />
      ))}

      {picks.length !== 0 && (
        <PickLayer
          picks={picks[pickSelected].pins}
          size={700 - layers.length * 100 + layers.length * 100 + 100}
          offset={picks[pickSelected].offset}
          ref={pickRef}
        />
      )}

      <Grid
        container
        sx={{
          position: "absolute",
          right: "0px",
          width: "25vw",
          background: "#202020",
        }}
      >
        {picks.map((pick, index) => (
          <Grid
            item
            xs
            sx={{
              width: "100px",
              height: "100px",
            }}
          >
            <PickLayerPreview
              picks={pick.pins}
              offset={pick.offset}
              index={index}
            />
          </Grid>
        ))}
      </Grid>

      {picks.length === 0 && (
        <Box
          sx={{
            position: "absolute",

            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "green",
              textShadow: "0px 0px 10px green",
              fontSize: "10rem",
              fontWeight: "bold",
              textAlign: "center",
              userSelect: "none",
              transition: "all linear 0.5s",

              "@keyframes success": {
                "0%": {
                  clipPath: "polygon(0 100%, 100% 0, 20% 0%, 0 100%)",
                  transform: "scale(1)",
                },
                "25%": {
                  clipPath: "polygon(0% 0%, 100% 100%, 100% 0%, 100% 100%)",
                  transform: "scale(2)",
                },
                "50%": {
                  clipPath: "polygon(100% 0%, 0% 100%, 0% 100%, 100% 0%)",
                  transform: "scale(2)",
                },
                "100%": {
                  clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
                  transform: "scale(1)",
                },
              },
              animation: "success 1.25s linear 0s forwards",
            }}
          >
            Success
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              window.location.reload();
            }}
          >
            Restart
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default App;
