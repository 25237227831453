import { Box } from "@mui/material"

function LockLayer(
    { 
        insets,
        size
    }:{ 
        insets: number[],
        size: number
    }
) {
  return (
    <Box sx={{
        position: "absolute",
        background: "#fff",
        width: `${size}px`,
        aspectRatio: "1/1",
        
        borderRadius: "50%",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all linear 0.5s",
    }}>
        <Box sx={{
            background: "#121212",
            width: `${size-40}px`,
            aspectRatio: "1/1",

            borderRadius: "50%",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "all linear 0.5s",
        }}>
            {insets.map((inset, index) => (
                <Box sx={{
                    position: "absolute",
                    width: "30px",
                    height: "30px",

                    transform: `rotate(${inset*15}deg) translateY(${size/2 - 11}px)`,

                    background: "#121212",
                }} />
            ))}
        </Box>
    </Box>
  )
}

export default LockLayer