import useLevelState, { Layer, Pick, PossibleInsets } from "./states/level";

type minMax = [number, number];

export default function generateLevel({
    layerCountRange,
    insetsPerLayerRange,
    maxPinsPerPick
}: {
    layerCountRange: minMax,
    insetsPerLayerRange: minMax,
    maxPinsPerPick: number
}) {
    const layerCount = randomInRange(layerCountRange)
    const layers: Layer[] = [];
    let picks: Pick[] = [];

    for (let i = 0; i < layerCount; i++) {
        const insets: PossibleInsets[] = generateInsets(randomInRange(insetsPerLayerRange));
        let picksForLayer: Pick[] = [];

        let avaliableInsets: PossibleInsets[] = JSON.parse(JSON.stringify(insets))

        for (let i = 0; i < insets.length; i++) {
            if (avaliableInsets.length === 0) break;
            const pinCount = Math.min(randomInRange([1, maxPinsPerPick]), avaliableInsets.length);
            let pins: PossibleInsets[] = [];

            for (let i = 0; i < pinCount; i++) {
                const selectedInset = randomInRange([0, avaliableInsets.length - 1])

                pins.push(avaliableInsets[selectedInset])
                avaliableInsets.splice(selectedInset, 1)
            }

            picksForLayer.push({
                pins,
                offset: Math.floor(Math.random() * 100),
                rotateCW: () => useLevelState.setState((state) => {
                    state.picks[state.pickSelected].offset++;
                    return ({
                        picks: state.picks
                    })
                }),
                rotateCCW: () => useLevelState.setState((state) => {
                    state.picks[state.pickSelected].offset--;
                    return ({
                        picks: state.picks
                    })
                })
            })
        }

        picks.push(...picksForLayer)
        layers.push({
            insets,
        })
    }

    picks = shuffleArray(picks)

    useLevelState.setState(() => ({
        layer: 0,
        totalLayers: layers.length,
        layers,
        pickSelected: 0,
        picks
    }))
}

function randomInRange(range: minMax) {
    return range[0] + Math.round(Math.random() * (range[1] - range[0]))
}

function shuffleArray(array: any[]) {
    const newArray = [...array]
    const length = newArray.length

    for (let start = 0; start < length; start++) {
        const randomPosition = Math.floor((newArray.length - start) * Math.random())
        const randomItem = newArray.splice(randomPosition, 1)

        newArray.push(...randomItem)
    }

    return newArray
}

function generateInsets(count: number): PossibleInsets[] {
    if (count <= 0) throw new Error("Generated Insets lower or equal to 0")
    if (count > 10) throw new Error("Generated Insets larger than 10")

    let insets: PossibleInsets[] = [];

    for (let i = 0; i < count; i++) insets.push(generateInset(insets))
    return insets
}

function generateInset(insets: PossibleInsets[]): PossibleInsets {
    const inset = Math.floor(Math.random() * 24) as PossibleInsets;

    if (insets.includes(inset)) return generateInset(insets)
    else return inset
}