import { create } from "zustand";

type PossibleInsets = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23;

interface LevelState {
    layers: Layer[];
    totalLayers: number;
    pickSelected: number; 
    picks: Pick[];
    setPickSelected: (pick: number) => void;
    removeInsets: (insets: PossibleInsets[]) => number;
    removePick: (pick: number) => void;
    removeCurrLayer: () => void;
}

interface Layer {
    insets: PossibleInsets[];
}

interface Pick {
    pins: PossibleInsets[];
    offset: number;
    rotateCW: () => void;
    rotateCCW: () => void;
}

const useLevelState = create<LevelState>((set) => ({
    layers: [],
    totalLayers: 0,
    pickSelected: 0,
    picks: [],
    setPickSelected: (pick) => set(() => ({
        pickSelected: pick
    })),
    removeInsets: (insets): number => {
        set((state) => {
            state.layers[0].insets = state.layers[0].insets.filter((inset) => !insets.includes(inset))
            return ({
                layers: state.layers
            })
        })
        return useLevelState.getState().layers[0].insets.length
    },
    removePick: (pick) => set((state) => {
        state.picks.splice(pick, 1)
        return ({
            picks: state.picks
        })
    }),
    removeCurrLayer: () => set((state) => {
        state.layers.splice(0, 1)
        return ({
            layers: state.layers
        })
    })
}));

export default useLevelState
export type { LevelState, Layer, Pick, PossibleInsets }